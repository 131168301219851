import React from "react"
import PropTypes from "prop-types"

function About(props) {
  const { text } = props

  return (
    <>
      <div className="w-full mt-24">
        <h1 className="bg-adobe items-center my-10 px-5 py-3 font-bold text-white text-4xl lg:text-5xl text-center mb-16">
          About Goosefoot
        </h1>
      </div>
      <div
        className="flex flex-wrap lg:w-5/7 h-full my-2 mx-10 lg:mx-auto lg:text-center items-center"
        style={{ maxWidth: "1400px" }}
      >
        <div
          dangerouslySetInnerHTML={{ __html: text }}
          className="font-serif font-light text-xl lg:text-2xl leading-tight mb-10"
        ></div>

        <a
          href="https://www.goosefoot.org/"
          className="bg-red hover:bg-adobe text-white font-bold font-serif py-3 px-10 text-xl rounded place-self mx-auto mb-16"
        >
          LEARN MORE
        </a>

        <h2 className="w-full text-rust text-4xl lg:text-6xl mt-4 mb-2">
          Newsletter Sign Up
        </h2>

        <p className="font-serif font-light text-xl lg:text-2xl leading-tight mb-10">
          Receive a monthly email with Goosefoot news and information on
          community events, live music, art shows, and more.
        </p>

        <form
          className="w-full grid grid-cols-1 lg:grid-cols-3 gap-10 mb-12"
          name="newsletter-signup"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          method="POST"
          action="/thank-you"
        >
          {/*data-netlify-recaptcha="true"*/}
          <input type="hidden" name="form-name" value="newsletter-signup" action="/thank-you" />
          <p hidden>
            <label>
              Don’t fill this out:{" "}
              <input name="bot-field"/>
            </label>
          </p>
          <input
            type="text"
            id="firstname"
            name="firstname"
            placeholder="First Name"
            aria-label="First Name"
            className="field text-gray-700 focus:outline-none focus:shadow-outline"
          />
          <input
            type="text"
            id="lastname"
            name="lastname"
            placeholder="Last Name"
            aria-label="Last Name"
            className="field text-gray-700 focus:outline-none focus:shadow-outline"
          />
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Email Address"
            aria-label="Email Address"
            className="field text-gray-700 focus:outline-none focus:shadow-outline"
          />
          {/*<div data-netlify-recaptcha="true"></div>*/}
          <button
            type="submit"
            className="bg-red hover:bg-adobe text-white font-bold font-serif py-3 px-10 text-xl rounded lg:col-start-2 col-span-1 place-self"
          >
            SIGN UP NOW
          </button>
        </form>
      </div>
    </>
  )
}

About.propTypes = {
  text: PropTypes.string.isRequired,
}

export default About
