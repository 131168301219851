import React from "react"
import PropTypes from "prop-types"
import { unescape } from "underscore/modules/index"
import Img from "gatsby-image"
import FarmstandImg from "../images/farmstand.png"
import Icons from "./Icons"

const Farmstand = (props) => {
  const {
    title,
    id,
    image,
    description,
    owner,
    location,
    city,
    dates,
    hours,
    website,
    facebook,
    instagram,
    twitter,
    seeds,
    farmstand,
    csa,
    market
  } = props

  const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${location.address}` 

  return (
    <div
      className="py-8 border-adobe border-solid border-b-2 md:grid grid-cols-3 gap-10 w-full items-start"
      data-id={id}
      data-seeds={seeds}
      data-csa={csa}
      data-market={market}
      data-city={city}
      data-farmstand={farmstand || 'yes'}
    >
      { image !== null && image.fluid &&
        <Img
          fluid={image.fluid}
          alt={unescape(title)}
          lazy="true"
          className="w-full"
        />
      }
      { image === null &&
        <img src={FarmstandImg} alt={unescape(title)} className="w-full" />
      }
      <div className="col-span-2">
        <h2 className="font-serif font-bold text-rust text-3xl">
          {unescape(title)}
        </h2>
        <p className="font-serif font-light text-black text-xl">
          Owner:
          <span className="ml-1 font-serif font-bold text-olive">{owner}</span>
        </p>
        <p className="font-serif font-light text-black text-xl">
          Location:
          <span className="ml-1 font-serif font-bold text-olive hover:text-rust ">
            <a target="_blank" rel="noopener noreferrer" href={mapsUrl}>{location.address.replace(', WA, USA', '')}</a>
          </span>
        </p>
        <p className="font-serif font-light text-black text-xl">
          Dates:
          <span className="ml-1 font-serif font-bold text-olive ">{dates}</span>
        </p>
        <p className="font-serif font-light text-black text-xl">
          Hours:
          <span className="ml-1 font-serif font-bold text-olive ">{hours}</span>
        </p>
        <div
          className="mt-2 font-serif font-light text-black text-xl"
          dangerouslySetInnerHTML={{ __html: description }}
        ></div>
        <Icons 
          website={website}
          facebook={facebook}
          instagram={instagram}
          twitter={twitter}
          location={location}
        />
      </div>
    </div>
  )
}

Farmstand.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  image: PropTypes.object,
  description: PropTypes.string.isRequired,
  owner: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  city: PropTypes.string,
  dates: PropTypes.string,
  hours: PropTypes.string,
  website: PropTypes.string,
  facebook: PropTypes.string,
  instagram: PropTypes.string,
  twitter: PropTypes.string,
  seeds: PropTypes.string.isRequired,
  csa: PropTypes.string.isRequired,
  market: PropTypes.string
}

export default Farmstand
