import React from "react"
import PropTypes from "prop-types"

function ShopLocal(props) {
  const { subtitle, text } = props

  return (
    <section>
      <div className="mt-10 mb-10 mx-auto px-10 pb-3 w-full md:px-0 md:w-9/12">
        <h3 className="text-center text-rust font-bold text-4xl lg:text-5xl leading-tight">
          {subtitle}
        </h3>
        <p className="font-serif text-center font-light leading-snug text-2xl lg:text-2xl">
          {text}
        </p>
      </div>
    </section>
  )
}

ShopLocal.propTypes = {
  subtitle: PropTypes.string.isRequired, 
  text: PropTypes.string.isRequired
}

export default ShopLocal
