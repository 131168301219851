import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Facebook, Instagram } from "react-feather"

function Follow() {
  const data = useStaticQuery(graphql`
    query {
      streetdanceImage: file(relativePath: { eq: "Street-Dance-Sepia.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const streetDance = data.streetdanceImage.childImageSharp.fluid
  return (
    <section className="grid grid-rows-1 items-center overflow-hidden" style={{maxHeight: '600px'}}>
      <div className="grid-area-11 z-0 flex" style={{minHeight: '100%'}}>
        <Img className="flex-100" fluid={streetDance} loading="lazy" />
      </div>
      <div className="grid-area-11 z-10 py-8 lg:py-0">
        <h1 className="text-center text-white font-bold text-white text-3xl lg:text-5xl">
          Follow Goosefoot
        </h1>
        <p className="font-serif text-white text-xl lg:text-2xl font-semibold text-center px-6 md:px-0 ">
          For news, events and photos from all over South Whidbey follow us on
          Facebook and Instagram.
        </p>
        <div className="flex items-center mt-6 justify-center">
          <a
            href="https://www.instagram.com/goosefoot_community_fund/"
            target="_blank"
            rel="noopener noreferrer"
            className="p-3 mr-3 bg-red hover:bg-adobe transition-colors duration-100 ease-linear rounded-full"
            style={{ fontSize: 0 }}
          >
            <Instagram size="32" color="white" />
          </a>
          <a
            href="https://www.facebook.com/goosefootcommunityfund"
            target="_blank"
            rel="noopener noreferrer"
            className="p-3 mr-3 bg-red hover:bg-adobe transition-colors duration-100 ease-linear rounded-full"
            style={{ fontSize: 0 }}
          >
            <Facebook size="32" color="white" />
          </a>
        </div>
      </div>
    </section>
  )
}

export default Follow
