import React, { useState } from "react"

const Filters = props => {
  const [checked, setChecked] = useState("farmstand")

  const filters = [
    {
      label: "All Farm Stands & Markets",
      value: "farmstand",
      attr: "farmstand",
    },
    {
      label: "Clinton Farm Stands",
      value: "clinton",
      attr: "city",
    },
    {
      label: "Coupeville Farm Stands",
      value: "coupeville",
      attr: "city",
    },
    // {
    //  label: "Greenbank Farm Stands",
    //  value: "greenbank",
    //  attr: "city"
    // },
    {
      label: "Freeland Farm Stands",
      value: "freeland",
      attr: "city",
    },
    {
      label: "Langley/Bayview Farm Stands",
      value: "langley",
      attr: "city",
    },
    {
      label: "Oak Harbor Farm Stands",
      value: "oak-harbor",
      attr: "city",
    },
    {
      label: "Farmers Markets",
      value: "market",
      attr: "market",
    },
    {
      label: "CSA shares (Community Supported Agriculture)",
      value: "csa",
      attr: "csa",
    },
    {
      label: "Seeds",
      value: "seeds",
      attr: "seeds",
    },
  ]

  const handleOptionChange = filter => {
    props.setActiveFilter(filter)
    setChecked(filter.value)
  }

  return (
    <div>
      {filters.map((filter, i) => (
        <label
          className="flex items-center mt-2 font-serif font-bold text-rust text-xl"
          key={i}
        >
          <input
            type="radio"
            name="farmstand-filters"
            value={filter.value}
            data-search_in={filter.attr}
            checked={checked === filter.value}
            onChange={() => handleOptionChange(filter)}
          />
          <span className="ml-2">{filter.label}</span>
        </label>
      ))}
    </div>
  )
}

export default Filters
