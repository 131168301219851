import React from "react" 
import { Globe, MapPin, Facebook, Instagram, Twitter } from 'react-feather';
import PropTypes from "prop-types"

const Icons = (props) => {
  const {
    location,
    website,
    facebook,
    instagram,
    twitter
  } = props

  const WebsiteLink = () => (
	  website && <a href={website} target="_blank" rel="noopener noreferrer" className="p-2 mr-3 bg-olive transition-colors duration-100 ease-linear rounded-full tooltip--triangle" style={{fontSize: 0}} data-tooltip="Website"><Globe size="24" color="white" /></a>
  )
  const MapLink = () => {
  	const link = location && `https://www.google.com/maps/place/${location.address.replace(/\s/g, '+')}`
	  return location && <a href={link} target="_blank" rel="noopener noreferrer" className="p-2 mr-3 bg-olive transition-colors duration-100 ease-linear rounded-full tooltip--triangle" style={{fontSize: 0}} data-tooltip="Google Maps"><MapPin size="24" color="white" /></a>
  }
  const FacebookLink = () => (
	  facebook && <a href={facebook} target="_blank" rel="noopener noreferrer" className="p-2 mr-3 bg-olive transition-colors duration-100 ease-linear rounded-full tooltip--triangle" style={{fontSize: 0}} data-tooltip="Facebook"><Facebook size="24" color="white" /></a>
  )
  const TwitterLink = () => (
	  twitter && <a href={twitter} target="_blank" rel="noopener noreferrer" className="p-2 mr-3 bg-olive transition-colors duration-100 ease-linear rounded-full tooltip--triangle" style={{fontSize: 0}} data-tooltip="Twitter"><Twitter size="24" color="white" /></a>
  )
  const InstagramLink = () => (
	  instagram && <a href={instagram} target="_blank" rel="noopener noreferrer" className="p-2 mr-3 bg-olive transition-colors duration-100 ease-linear rounded-full tooltip--triangle" style={{fontSize: 0}} data-tooltip="Instagram"><Instagram size="24" color="white" /></a>
  )

  return (
    <div rel="noopener noreferrer" className="flex items-center gap-4 mt-4">
	    <WebsiteLink />
	    <MapLink />
	    <FacebookLink />
	    <TwitterLink />
	    <InstagramLink />
    </div>
  )

}


Icons.propTypes = {
  location: PropTypes.object,
  twitter: PropTypes.string,
  facebook: PropTypes.string,
  website: PropTypes.string,
  instagram: PropTypes.string
}

export default Icons