import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"

function Partner(props) {
  const { partners } = props

  // console.log(partners.length)
  // console.log("Partners", partners)
  
  return (
    <>
      <h1 className="bg-adobe items-center my-10 px-5 py-3 font-bold text-white text-4xl lg:text-5xl text-center">
        Additional Resources
      </h1>
      <div
        className="lg:grid grid-cols-2 gap-6 mx-auto"
        style={{ maxWidth: "1400px" }}
      >
        {partners.map( (partner, i) => (
          <div 
            key={i}
            className="flex flex-wrap items-center mb-20 lg:mb-0"
          >
            <a 
              className="w-2/5 lg:mx-auto ml-10 mb-6" 
              href={partner.link.url} 
              title={partner.link.title} 
              target="_blank"
              rel="noopener noreferrer"
            >
              <Img
                fluid={partner.logo.localFile.childImageSharp.fluid}
              />
            </a>
            <h2 className="w-full mx-10 my-2 font-serif font-bold text-rust text-3xl lg:text-4xl lg:text-center">
              {partner.title}
            </h2>
            <p 
              className="font-serif font-light text-xl lg:text-2xl lg:text-center leading-tight mx-10 xl:mx-24"
              dangerouslySetInnerHTML={{ __html: partner.text }}
            />
          </div>
        ))}
      </div>
    </>
  )
}

Partner.propTypes = {
  partners: PropTypes.array.isRequired
}

export default Partner
