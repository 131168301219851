import React, { useState } from 'react'
 
const Search = (props) => {
  const [query, setQuery] = useState('');

  function getSearchResults(query) {
      if (!query || !window.__LUNR__) return []
      const lunrIndex =  window.__LUNR__['en'];
      const results = lunrIndex.index.search(`${query} ${query}~1`) // you can  customize your search , see https://lunrjs.com/guides/searching.html
      return results.map(({ ref }) => ref); //lunrIndex.store[ref])
  }

  function search(event) {
      const query = event.target.value
      const results = getSearchResults(query)
      props.setSearchResults(results, query)
      setQuery(query)
  }

  return (
    <div className="link_bold w-full">
      <input
        type="search"
        id="search-input"
        name="search-input"
        aria-label="Please search for a farmstand"
        onChange={(e) => search(e)}
        value={query}
        placeholder="Search here"
        className="text-gray-700 field mt-4 focus:outline-none focus:shadow-outline"
      />
      
    </div>
  )
}

export default Search