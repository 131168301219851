import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"

function Banner(props) {
  const { title, image, alt } = props

  const strawberries = image.fluid

  return (
    <section>
      <h2 className="w-full bg-adobe md:mt-10 py-6 items-center font-serif font-semibold text-white text-2xl lg:text-3xl  text-center">
        {title}
      </h2>
      <Img
        fluid={strawberries}
        loading="lazy"
        alt={alt}
      />
    </section>
  )
}

Banner.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  alt: PropTypes.string.isRequired
}

export default Banner
