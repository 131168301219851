import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby-plugin-modal-routing"
import Layout from "../components/Layout1"
import SEO from "../components/SEO"
import Farmstand from "../components/Farmstand"
import Banner from "../components/Banner"
import ShopLocal from "../components/ShopLocal"
import Search from "../components/Search"
import Filters from "../components/Filters"
import Rules from "../components/Rules"
import Partner from "../components/Partner"
import About from "../components/About"
import Follow from "../components/Follow"

export function IndexPage({ data }) {
  const [filter, setFilter] = useState({
    label: "All Farm Stands & Markets",
    value: "farmstand",
    attr: "farmstand",
  })
  const [query, setQuery] = useState("")

  const { page } = data;

  const farmStands = data.allWordpressWpFarmstands.nodes
  const [stands, setStands] = useState(farmStands)

  const pluck = (arr, key) => arr.map(o => o[key])
  const ids = pluck(farmStands, "id") || []
  const [results, setResults] = useState(ids)

  const numStands = farmStands.length
  const [numResults, setNumResults] = useState(numStands)

  useEffect(() => {
    const matchesActiveFilter = stand => {
      if (filter.attr === "city") {
        let city = stand.acf.location.city
          ? stand.acf.location.city.replace(" ", "-").toLowerCase()
          : null
        console.log("City: ", stand, city, filter.value)
        return city === filter.value
      }
      if (filter.attr === "seeds") {
        return stand.acf.seeds === "yes"
      }
      if (filter.attr === "csa") {
        return stand.acf.csa === "yes"
      }
      if (filter.attr === "market") {
        return stand.acf.market === "yes"
      }
      if (filter.attr === "farmstand") {
        return stand.acf.farmstand === "yes"
      }
      return true
    }

    const filterStands = (farmStands, filter, query, results) => {
      const activeStands = farmStands.filter(stand => {
        const filterMatch = matchesActiveFilter(stand)
        const queryMatch =
          query === "" ||
          typeof query === "undefined" ||
          !results ||
          results.includes(stand.id)
        return filterMatch && queryMatch
      })
      setNumResults(activeStands.length)
      setStands(activeStands)
    }

    filterStands(farmStands, filter, query, results)
  }, [filter, query, results])

  function setSearchResults(results, query) {
    setResults(results)
    setQuery(query)
  }

  function setActiveFilter(filter) {
    setFilter(filter)
  }

  return (
    <Layout>
      <link
        href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&family=Teko:wght@300;400;500;600;700&display=swap"
        rel="stylesheet"
      ></link>
      <SEO title="Home" />
      <main>
        <div className="w-full lg:w-5/6 mx-auto" style={{ maxWidth: "1400px" }}>
          <Banner
            title={page.acf.shop_local.title}
            image={page.acf.shop_local.image.localFile.childImageSharp}
            alt={page.acf.shop_local.alt}
          />
          <ShopLocal
            subtitle={page.acf.shop_local.subtitle}
            text={page.acf.shop_local.text}
          />

          <div className="grid px-6 lg:px-0 pb-16 xl:grid-cols-3 gap-16 items-start">
            <div className="flex flex-wrap xl:col-span-1 xl:col-start-3 xl:row-start-1 xl:sticky top-0">
              <h2 className="w-full bg-olive px-5 py-2 mb-4 font-serif font-bold text-white text-2xl text-center">
                Categories
              </h2>
              <Filters setActiveFilter={setActiveFilter} />

              <h2 className="w-full bg-olive px-5 py-2 mt-10 mb-4 font-serif font-bold text-white text-2xl text-center">
                Search Our Farms
              </h2>
              {page.acf.sidebar.search.map( (p,i) => (
                <p key={i} className="w-full font-serif text-center font-light leading-snug text-xl md:text-xl py-1">
                  {p.text}
                </p>  
              ))}
              
              <div className="flex w-full justify-center text-center mb-4">
                <Search
                  className={"link_bold"}
                  setSearchResults={setSearchResults}
                />
              </div>

              <h2 className="w-full bg-olive px-5 py-2 mb-4 mt-10 font-serif font-bold text-white text-2xl text-center">
                Not on This List?
              </h2>
              <p className="font-serif text-center font-light leading-snug text-xl">
                {page.acf.sidebar.join}
              </p>

              <Link
                className="bg-red hover:bg-adobe text-white font-bold font-serif py-3 px-10 mt-4 mx-auto text-xl rounded"
                to="/join/"
                asModal
              >
                JOIN
              </Link>
            </div>

            <div className="flex flex-wrap xl:col-span-2 xl:col-start-1 xl:row-start-1">
              <h2 className="w-full bg-adobe px-5 py-2 font-serif font-bold text-white text-2xl flex justify-between items-center">
                <span className="whitespace-no-wrap">All Farms</span>
                <small>
                  Viewing {numResults} of {numStands}{" "}
                  <span className="hidden md:inline">Farm Stands</span>
                </small>
              </h2>

              {stands.map(stand => {
                return (
                  <Farmstand
                    key={stand.wordpress_id}
                    id={stand.id}
                    title={stand.title}
                    image={
                      stand.featured_media &&
                      stand.featured_media.localFile.childImageSharp
                    }
                    description={stand.content}
                    owner={stand.acf.owner}
                    location={stand.acf.location}
                    city={stand.acf.location.city}
                    dates={stand.acf.dates}
                    hours={stand.acf.hours}
                    website={stand.acf.website}
                    facebook={stand.acf.facebook}
                    instagram={stand.acf.instagram}
                    twitter={stand.acf.twitter}
                    seeds={stand.acf.seeds}
                    farmstand={stand.acf.farmstand}
                    csa={stand.acf.csa}
                    email={stand.acf.email}
                    phone={stand.acf.phone}
                    market={stand.acf.market}
                    visible
                  />
                )
              })}
            </div>
          </div>

          <Rules
            title={page.acf.rules.title}
            image={page.acf.rules.image.localFile.childImageSharp}
            text={page.acf.rules.text}
            alt={page.acf.rules.alt}
          />
        </div>
      </main>
      <Partner partners={page.acf.partners.partners} />
      <About text={page.acf.about} />
      <Follow />
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    page: wordpressPage(slug: { eq: "farmstands" }) {
      wordpress_id
      title
      slug
      acf {
        about
        sidebar {
          join
          search {
            text
          }
        }
        partners {
          partners {
            title
            text
            link {
              title
              url
            }
            logo {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 700) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              source_url
            }
          }
        }
        rules {
          text
          title
          alt
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 700) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            source_url
          }
        }
        shop_local {
          text
          title
          subtitle
          alt
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 700) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            source_url
          }
        }
      }
    }
    allWordpressWpFarmstands(limit: 1000, sort: { fields: title, order: ASC }) {
      nodes {
        title
        content
        slug
        wordpress_id
        id
        featured_media {
          localFile {
            childImageSharp {
              fluid(maxWidth: 440, maxHeight: 440) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          source_url
        }
        acf {
          farmstand
          csa
          dates
          facebook
          hours
          instagram
          owner
          location {
            city
            address
            lat
            lng
          }
          seeds
          twitter
          website
          email
          phone
          market
        }
      }
    }
  }
`

export default IndexPage
