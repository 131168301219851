import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"

function Rules(props) {
  const { title, text, image, alt } = props
  const blackberries = image.fluid

  return (
    <section>
      <div className="lg:grid grid-cols-2 items-center gap-10 my-16">
        <Img
          fluid={blackberries}
          //style={{ height: "600px" }}
          className="contain-image flex w-full"
          loading="lazy"
          alt={alt}
        />
        <div className="flex flex-wrap items-center space-around px-8">
          <h2 className="w-full text-rust text-4xl lg:text-6xl mt-4 mb-2">
            {title}
          </h2>
          <div
            dangerouslySetInnerHTML={{ __html: text }}
            className="p-with-leading font-serif font-light text-xl lg:text-2xl leading-tight mb-4"
          ></div>
        </div>
      </div>
    </section>
  )
}

Rules.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  alt: PropTypes.string.isRequired
}

export default Rules
